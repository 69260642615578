<template>
  <h2>{{ t('invoices') }}</h2>
  <div v-if="invoices" class="relative mt-4">
    <div>
      <template v-for="(invoice, i) in invoices" :key="i">
        <div class="relative flex gap-2 border-b border-primary/40 py-2">
          <div class="grid w-full grid-cols-4 items-center gap-4">
            <div>
              {{ invoice.title || '-' }}
            </div>
            <div>
              <span>{{ invoice.completeInvoiceNumber ?? t('invoiceNrNotAvailable') }}</span>
            </div>
            <div>
              {{ invoice.invoiceDate ? formatDate(invoice.invoiceDate, dateFormat) : '-' }}
            </div>
            <div>{{ t(`invoiceStatus.${invoice.status}`) }}</div>
          </div>
          <DropdownDotted :buttons="dropdownButtons[i]" />
        </div>
        <InvoicesForm
          v-if="
            (editingInvoice[invoice.id] || props.defaultExpanded) &&
            invoice.status === InvoiceStatus.DRAFT
          "
          :booking="booking"
          :invoices="invoices"
          :payments="payments"
          :invoice="invoice"
          @on-submit="
            (data, sendInvoiceOnSave) => handleUpdateInvoice(data, invoice.id, sendInvoiceOnSave)
          "
          @on-cancel="editingInvoice[invoice.id] = false"
        />
      </template>
      <div v-if="invoices.length === 0" class="pb-3 pt-1">
        {{ t('noInvoices') }}
      </div>
    </div>

    <InvoicesForm
      v-if="addingInvoice"
      :booking="booking"
      :invoices="invoices"
      :payments="payments"
      @on-submit="handleCreateInvoice"
      @on-cancel="addingInvoice = false"
    />

    <div v-else-if="$can('create', 'Invoice')" class="mt-5 flex justify-end">
      <CVButton @click.prevent="addingInvoice = true">{{ t('addInvoice') }}</CVButton>
    </div>
    <div
      v-if="isCreating || isDeleting || isUpdating || isSending"
      class="absolute inset-[-10px] flex items-center justify-center bg-black bg-opacity-30"
    >
      <Spinner />
    </div>
  </div>
  <div v-else class="flex h-20 items-center justify-center">
    <Spinner />
  </div>
</template>

<script lang="ts" setup>
import { FindAllPaymentsSort } from '@/entities/find-all-payments-sort.enum';
import { FindAllInvoicesSort } from '@/entities/invoices/find-all-invoices-sort.enum';
import { InvoiceStatus } from '@/entities/invoices/invoice-status.enum';
import type { CreateInvoiceDto, Invoice } from '@/entities/invoices/invoice.entity';
import { Order } from '@/entities/pagination/order.enum';
import { useFlattenPaginatedData } from '@/hooks/use-flatten-paginated-data';
import { formatDate } from '@/hooks/use-formatted-date';
import { useInvoicePdfs } from '@/hooks/use-invoice-pdf';
import { useI18n } from 'vue-i18n';
import {
  useCreateInvoice,
  useDeleteInvoice,
  useInvoicesWithQuery,
  useUpdateInvoice,
} from '@/queries/use-invoices';
import { usePaymentsWithQuery } from '@/queries/use-payments';
import { Alert } from '@/utils/alert';
import { computed, reactive, ref } from 'vue';
import DropdownDotted from '../DropdownDotted.vue';
import InvoicesForm from './InvoicesForm.vue';
import type { Booking } from '@/entities/bookings/booking.entity';

const props = defineProps<{
  booking: Booking;
  defaultExpanded?: boolean;
}>();

const { t } = useI18n();

const editingInvoice = reactive<Record<string, boolean>>({});
const addingInvoice = ref(false);

const dateFormat = 'dd.MM.yy';

const queryParams = {
  filter: [
    {
      bookingIds: [props.booking.id],
    },
  ],
  sort: FindAllInvoicesSort.INVOICE_DATE,
  order: Order.ASC,
};

const { data: invoicesData } = useInvoicesWithQuery(queryParams);
const { mutateAsync: createInvoice, isPending: isCreating } = useCreateInvoice(queryParams);
const { mutateAsync: updateInvoice, isPending: isUpdating } = useUpdateInvoice(queryParams);
const { mutateAsync: deleteInvoice, isPending: isDeleting } = useDeleteInvoice(queryParams);
const { data: paymentsData } = usePaymentsWithQuery({
  bookingIds: [props.booking.id],
  limit: 100,
  sort: FindAllPaymentsSort.CREATED_DATE,
  order: Order.ASC,
});

const { downloadInvoice, sendInvoiceToCustomer, sendInvoiceToCustomerWithAlert, isSending } =
  useInvoicePdfs();

const invoices = useFlattenPaginatedData(invoicesData);
const payments = useFlattenPaginatedData(paymentsData);
const handleCreateInvoice = async (newInvoice: CreateInvoiceDto, sendInvoiceOnSave: boolean) => {
  const { id } = await createInvoice(newInvoice);
  if (sendInvoiceOnSave) {
    await sendInvoiceToCustomer(id);
  }
  addingInvoice.value = false;
};

const handleUpdateInvoice = async (
  invoice: CreateInvoiceDto,
  id: string,
  sendInvoiceOnSave: boolean,
) => {
  await updateInvoice({
    id,
    invoice,
  });
  if (sendInvoiceOnSave && invoice.status !== InvoiceStatus.DRAFT) {
    await sendInvoiceToCustomer(id);
  }
  editingInvoice[id] = false;
};

const cancelInvoice = async (invoiceId: string) => {
  const alertResult = await Alert.fire({
    titleText: t('reallyCancelInvoiceTitle'),
    text: t('reallyCancelInvoiceText'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('cancelInvoice'),
    denyButtonText: t('dontCancel'),
  });
  if (alertResult.isConfirmed) {
    updateInvoice({
      id: invoiceId,
      invoice: { status: InvoiceStatus.CANCELED },
    });
  }
};

const removeInvoice = async (invoice: Invoice) => {
  const alertResult = await Alert.fire({
    titleText: t('reallyRemoveInvoiceTitle'),
    text: t('reallyRemoveInvoiceText'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('removeInvoice'),
    denyButtonText: t('keep'),
  });
  if (alertResult.isConfirmed) {
    await updateInvoice({
      id: invoice.id,
      invoice: {
        paymentIds: [],
      },
    });
    deleteInvoice(invoice.id);
  }
};

const dropdownButtons = computed(() =>
  invoices.value.map((invoice) =>
    invoice.status === InvoiceStatus.DRAFT
      ? [
          ...(props.defaultExpanded
            ? []
            : [
                {
                  title: t('editInvoice'),
                  onClick: () => (editingInvoice[invoice.id] = true),
                },
              ]),
          {
            title: t('removeInvoice'),
            onClick: () => removeInvoice(invoice),
          },
        ]
      : [
          {
            title: t('download'),
            onClick: () => downloadInvoice(invoice),
          },
          {
            title: t('sendToCustomer'),
            onClick: () => sendInvoiceToCustomerWithAlert(invoice.id),
          },
          {
            title: t('cancelInvoice'),
            onClick: () => cancelInvoice(invoice.id),
          },
        ],
  ),
);
</script>

<i18n lang="json">
{
  "en": {
    "invoices": "Invoices",
    "invoiceNrNotAvailable": "INr. n/a",
    "addInvoice": "+ Add Invoice",
    "reallyRemoveInvoiceTitle": "Really remove Invoice?",
    "reallyRemoveInvoiceText": "The Invoice will be permanently removed.",
    "reallyCancelInvoiceTitle": "Really cancel Invoice?",
    "reallyCancelInvoiceText": "The Invoice will be permanently canceled.",
    "keep": "Don't remove",
    "dontCancel": "Don't cancel",
    "download": "Download (PDF)",
    "noInvoices": "This booking has no invoices",
    "payments": "Payments",
    "removeInvoice": "Remove Invoice",
    "cancelInvoice": "Cancel Invoice",
    "sendToCustomer": "Send to Customer",
    "editInvoice": "Edit Invoice"
  },
  "de": {
    "invoices": "Rechnungen",
    "invoiceNrNotAvailable": "RNr. n/a",
    "addInvoice": "+ Rechnung hinzufügen",
    "reallyRemoveInvoiceTitle": "Rechnung wirklich löschen?",
    "reallyRemoveInvoiceText": "Die Rechnung wird unwiderruflich gelöscht.",
    "reallyCancelInvoiceTitle": "Rechnung wirklich stornieren?",
    "reallyCancelInvoiceText": "Die Rechnung wird unwiderruflich storniert.",
    "keep": "Nicht löschen",
    "dontCancel": "Nicht stornieren",
    "download": "Runterladen (PDF)",
    "noInvoices": "Diese Buchung hat noch keine Rechnungen",
    "payments": "Zahlungen",
    "removeInvoice": "Löschen",
    "cancelInvoice": "Rechnung stornieren",
    "sendToCustomer": "An Kunde senden",
    "editInvoice": "Rechnung bearbeiten"
  }
}
</i18n>
