<template>
  <HeaderBar />
  <ButtonBackContainer>
    <ButtonBack>{{ t('backToVehicleTypesList') }}</ButtonBack>
  </ButtonBackContainer>

  <section class="carvia-container">
    <div v-if="!isLoading">
      <VehicleTypeStats
        v-if="vehicleType"
        :vehicle-type="vehicleType"
        :hide-edit-button="!canEdit"
      />

      <Divider />

      <div class="flex justify-between">
        <h2>{{ t('vehiclesOfVehicleType') }}</h2>
        <CVButton
          v-if="canEdit"
          :disabled="!vehicleType"
          @click="
            $router.push({
              name: 'addNewCar',
              params: { vehicleTypeId: vehicleType?.id },
            })
          "
        >
          {{ t('addVehicle') }}
        </CVButton>
      </div>

      <div v-if="vehicleType" class="mt-4 flex flex-col space-y-2">
        <div v-for="(car, index) in visibleCars" :key="car.id">
          <CarCard
            :car="car"
            :vehicle-type="vehicleType"
            :cannot-edit="!canEdit"
            @click="chosenCar = index"
          />
        </div>
      </div>
    </div>
    <div v-else class="mt-10 flex items-center justify-center">
      <Spinner />
    </div>
  </section>
</template>

<script setup lang="ts">
import { useUiStore } from '@/stores/ui.store';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useVehicleType } from '@/queries/use-vehicle-types';
import { watchEffect, ref, computed } from 'vue';
import VehicleTypeStats from './VehicleTypeStats.vue';
import Divider from '@/components/Divider.vue';
import CarCard from '../vehicle-types-list/CarCard.vue';
import HeaderBar from '@/components/headerbar/HeaderBar.vue';
import ButtonBackContainer from '@/components/ButtonBackContainer.vue';
import ButtonBack from '@/components/buttons/ButtonBack.vue';
import { useAuthStore } from '@/stores/auth.store';
import { UserRole } from '@/entities/auth/user-role.enum';

const { t } = useI18n();
const uiStore = useUiStore();
const authStore = useAuthStore();
const route = useRoute();
const { data: vehicleType, isLoading: isLoading } = useVehicleType(route.params.id as string);

const canEdit = computed(() => authStore.user?.role === UserRole.ADMIN);

const visibleCars = computed(() =>
  canEdit.value
    ? vehicleType.value?.cars
    : vehicleType.value?.cars.filter((car) => !car.isGhostCar),
);

watchEffect(() => {
  if (vehicleType.value) {
    const name = vehicleType.value.make.name;
    const model = vehicleType.value.model;
    uiStore.setHeaderTitle(t('vehicleTypes'), name, model);
  }
});

const chosenCar = ref(0);
</script>

<i18n lang="json">
{
  "en": {
    "vehicleTypes": "Vehicle Types",
    "newVehicleType": "New Vehicle Type",
    "addVehicle": "+ add vehicle",
    "vehiclesOfVehicleType": "Vehicles of the Vehicle Type",
    "backToVehicleTypesList": "Back to Vehicle Types List"
  },
  "de": {
    "vehicleTypes": "Vehicle Types",
    "newVehicleType": "Neue Fahrzeugklasse",
    "addVehicle": "+ Fahrzeug hinzufügen",
    "vehiclesOfVehicleType": "Fahrzeuge des Vehicle Type",
    "backToVehicleTypesList": "Zurück zur Vehicle Types Liste"
  }
}
</i18n>
