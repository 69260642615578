<template>
  <router-link
    :to="{
      name: 'invoice',
      params: { id: invoice.id },
    }"
  >
    <div
      class="relative w-full border transition-shadow hover:shadow-md"
      :class="{
        'border-booking-open-content/30 bg-booking-open': status === InvoiceListItemType.PAID,
        'border-booking-warning-content/30 bg-booking-warning':
          status === InvoiceListItemType.NOT_PAID,
        'border-booking-danger-content/30 bg-booking-danger':
          status === InvoiceListItemType.OVERDUE,
        'border-booking-open-content/30 bg-booking-canceled':
          status === InvoiceListItemType.CANCELED,
        'border-booking-open-content/30 bg-booking-reversal':
          status === InvoiceListItemType.REVERSAL,
      }"
    >
      <div class="flex items-center gap-2 py-1 pl-3 pr-2 text-sm">
        <div class="basis-[21%]">
          <InvoiceListItemTitle :status="status"> {{ t('date') }}</InvoiceListItemTitle>
          <p>{{ invoiceDate }}</p>
        </div>

        <div class="basis-[17%]">
          <InvoiceListItemTitle :status="status">{{ t('invoiceNumber') }}</InvoiceListItemTitle>
          <p>{{ invoice.completeInvoiceNumber }}</p>
        </div>

        <div class="basis-[31%]">
          <InvoiceListItemTitle :status="status">{{ t('customer') }}</InvoiceListItemTitle>
          <p>
            {{ invoice.receiverData.firstName }}
            {{ invoice.receiverData.lastName }}
          </p>
        </div>

        <div class="basis-[11%]">
          <InvoiceListItemTitle :status="status">{{ t('bookingNumber') }}</InvoiceListItemTitle>
          <p>
            {{ invoice.booking ? invoice.booking.bookingNumber : 'n/a' }}
          </p>
        </div>

        <div class="basis-[20%]">
          <InvoiceListItemTitle :status="status">{{ t('total') }}</InvoiceListItemTitle>
          <p>
            <span v-currency="invoice.totalPositionSum" />
          </p>
        </div>

        <DropdownDotted :buttons="dropDownItems" />
      </div>
    </div>
  </router-link>
</template>

<script setup lang="ts">
import type { Invoice } from '@/entities/invoices/invoice.entity';
import { useFormattedDate } from '@/hooks/use-formatted-date';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import type { DropdownItem } from '@/components/DropdownDotted.vue';
import DropdownDotted from '@/components/DropdownDotted.vue';
import InvoiceListItemTitle from './InvoiceListItemTitle.vue';
import { InvoiceStatus } from '@/entities/invoices/invoice-status.enum';
import { InvoiceListItemType } from '@/entities/invoices/invoice-list-item-type.enum';
import { useInvoicePdfs } from '@/hooks/use-invoice-pdf';
import { Alert } from '@/utils/alert';
import { useUpdateInvoice } from '@/queries/use-invoices';
import { InvoiceType } from '@/entities/invoices/invoice-type.enum';

const props = defineProps<{
  invoice: Invoice;
}>();

const { t } = useI18n();

const invoiceDate = useFormattedDate(
  computed(() => props.invoice.invoiceDate),
  DateTime.DATE_MED,
);

const status = computed(() => {
  if (props.invoice.status === InvoiceStatus.CANCELED) {
    return InvoiceListItemType.CANCELED;
  }
  if (props.invoice.type === InvoiceType.REVERSAL) {
    return InvoiceListItemType.REVERSAL;
  }
  if (props.invoice.status === InvoiceStatus.PAID) {
    return InvoiceListItemType.PAID;
  }
  if (
    props.invoice.invoiceDate &&
    -DateTime.fromISO(props.invoice.invoiceDate).startOf('day').diffNow('days').as('days') < 14
  ) {
    return InvoiceListItemType.NOT_PAID;
  }
  return InvoiceListItemType.OVERDUE;
});

const { mutateAsync: updateInvoice } = useUpdateInvoice();

const { downloadInvoice, sendInvoiceToCustomerWithAlert } = useInvoicePdfs();

const cancelInvoice = async (invoiceId: string) => {
  const alertResult = await Alert.fire({
    titleText: t('reallyCancelInvoiceTitle'),
    text: t('reallyCancelInvoiceText'),
    icon: 'warning',
    showDenyButton: true,
    confirmButtonText: t('cancelInvoice'),
    denyButtonText: t('dontCancel'),
  });
  if (alertResult.isConfirmed) {
    updateInvoice({
      id: invoiceId,
      invoice: { status: InvoiceStatus.CANCELED },
    });
  }
};

const dropDownItems = computed<DropdownItem[]>(() => {
  return [
    {
      onClick: () => downloadInvoice(props.invoice),
      title: t('download'),
    },
    {
      onClick: () => sendInvoiceToCustomerWithAlert(props.invoice.id),
      title: t('sendToCustomer'),
    },
    {
      title: t('cancelInvoice'),
      onClick: () => cancelInvoice(props.invoice.id),
    },
  ];
});
</script>

<i18n lang="json">
{
  "en": {
    "date": "Date",
    "invoiceNumber": "INr.",
    "customer": "Customer",
    "bookingNumber": "Bnr.",
    "total": "Total",
    "cancelInvoice": "Cancel Invoice",
    "reallyCancelInvoiceTitle": "Really cancel Invoice?",
    "reallyCancelInvoiceText": "The Invoice will be permanently canceled.",
    "dontCancel": "Don't cancel",
    "download": "Download (PDF)",
    "sendToCustomer": "Send to Customer",
    "resendConfirmEmail": "Resend Booking Confirmation"
  },
  "de": {
    "date": "Datum",
    "invoiceNumber": "RNr.",
    "customer": "Kunde",
    "bookingNumber": "Bnr.",
    "total": "Betrag",
    "cancelInvoice": "Rechnung stornieren",
    "reallyCancelInvoiceTitle": "Rechnung wirklich stornieren?",
    "reallyCancelInvoiceText": "Die Rechnung wird unwiderruflich storniert.",
    "dontCancel": "Nicht stornieren",
    "download": "Runterladen (PDF)",
    "sendToCustomer": "An Kunde senden",
    "resendConfirmEmail": "Buchungsbestätigung erneut senden"
  }
}
</i18n>
